//
// slimscroll.scss
//

@import '~simplebar/dist/simplebar.min.css';

.simplebar-scrollbar:before {
    background: rgb(158, 165, 171) !important;
}

.slimScrollDiv {
    height: auto !important;
}