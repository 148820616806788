//
// buttons.scss
//

.btn {
	// color: $white;
	// color: #98A6AD;

	.mdi {
		&:before {
			margin-top: -1px;
		}
	}
}

.btn-rounded {
	border-radius: 2em;
	cursor: pointer;

	.btn-label {
		border-radius: 2em 0 0 2em;
	}

	.btn-label-right {
		border-radius: 0 2em 2em 0;
	}
}

.btn-head {
	background-color: #51C1AD !important;
	font-size: 13px;
	color: $white;
	border-radius: 0px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.btn-create {
	background-color: #51C1AD;
	font-size: 13px !important;
	color: $white;
}

.btn-cancel {
	background-color: #E0E0E0;
	font-size: 13px !important;
	color: #817F7F;
}

//
// light button
//
.btn-light,
.btn-white {
	color: $gray-900;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
	font-weight: $font-weight-normal;
	color: $link-color;
	background-color: transparent;

	@include hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		background-color: transparent;
		border-color: transparent;
	}

	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
		border-color: transparent;
		box-shadow: none;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	}

	// No need for an active state here
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

//
// Button labels
//

.btn-label {
	margin: -.55rem .9rem -.55rem -.9rem;
	padding: .6rem .9rem;
	background-color: rgba($gray-900, 0.1);
}

.btn-label-right {
	margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
	padding: ($btn-padding-y) ($btn-padding-x);
	background-color: rgba($gray-900, 0.1);
	display: inline-block;
}

//
// Button Extra Small Size
//

.btn-xs {
	padding: .2rem .6rem;
	font-size: .75rem;
	border-radius: .15rem;
}
