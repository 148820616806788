//
// rangeslider.scss
//
.vue-slide-bar-tooltip {
  background-color: #6658dd !important;
  border: 1px solid #6658dd !important;
}

.vue-slide-bar-process {
  background-color: #6658dd !important;
}
